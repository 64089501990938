import * as React from 'react';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';

export default function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 6, mb: 3, color: 'text.secondary' }}>
      {'Copyright © '}
      <MuiLink color="inherit" href="https://superplayer.site/">
        超玩社区
      </MuiLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
