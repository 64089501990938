import * as React from 'react';
import ResponsiveHead from './global/ResponsiveHead';
import Container from '@mui/material/Container';
import Copyright from './global/Copyright';
import Navbar from "./Navbar";
import IndexDataGrid from "./IndexDataGrid";

export default function Layout() {
  return (
    <Container maxWidth="lg" sx={{ bgcolor: '#cfe8fc'}}>
      <ResponsiveHead/>
      <Navbar/>
      <IndexDataGrid/>
      {/*<IndexMiddleNav/>*/}
      <Copyright/>
    </Container>
  );
}
