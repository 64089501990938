import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export default function Navbar() {
  const [value, setValue] = React.useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      textColor="secondary"
      indicatorColor="secondary"
      aria-label="secondary tabs example"
    >
      <Tab value="/" label="FFFF"/>
      <Tab value="two" label="私服发布站"/>
      <Tab value="three" label="资源分享"/>
      <Tab value="four" label="私服论坛"/>
    </Tabs>
  );
}
